import { createSelector } from 'reselect'

export const getDialogs = state => state.get('dialogs')

export const getAll = createSelector(
  getDialogs,
  dialogs => dialogs.get('all')
)

// PATIENT DIALOG
const getPatientDialog = createSelector(getAll, allDialogs => allDialogs.get('patient'))
export const isPatientDialogOpened = createSelector(getPatientDialog, patientDialog => patientDialog.get('open'))
export const getPatientDialogData = createSelector(getPatientDialog, patientDialog => patientDialog.get('data'))

// APPOINTMENT DIALOG
const getAppointmentDialog = createSelector(getAll, allDialogs => allDialogs.get('appointment'))
export const isAppointmentDialogOpened = createSelector(
  getAppointmentDialog, appointmentDialog => appointmentDialog.get('open')
)
export const getAppointmentDialogData = createSelector(
  getAppointmentDialog, appointmentDialog => appointmentDialog.get('data')
)

// TREATMENT DIALOG
const getTreatmentDialog = createSelector(getAll, allDialogs => allDialogs.get('treatment'))
export const isTreatmentDialogOpened = createSelector(
  getTreatmentDialog, treatmentDialog => treatmentDialog.get('open')
)
export const getTreatmentDialogData = createSelector(
  getTreatmentDialog, treatmentDialog => treatmentDialog.get('data')
)
