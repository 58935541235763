import Immutable from 'immutable'
import { createReducer } from 'redux-immutablejs'

import createDeferredReducer from '../utils/createDeferredReducer'
import { ADD, UPDATE, GET_ALL, GET_BY_ID, REMOVE } from './actions'
import * as treatmentActions from '../treatments/actions'
import { SUCCESS_SUFFIX, FAILURE_SUFFIX, REQUEST_SUFFIX } from '../utils/promiseMiddleware'
const {
  deferredCreate,
  deferredFailure,
  deferredSuccess,
} = createDeferredReducer('patients')

const initialState = Immutable.fromJS({
  all: Immutable.Map(),
  deferreds: {},
  isAllFetched: false,
})

function mergePatientInState(state, action) {
  return state.mergeIn(['all'], Immutable.fromJS(action.payload.response.entities.patient))
}

export default createReducer(initialState, {
  [`${ADD}${SUCCESS_SUFFIX}`]: mergePatientInState,
  [`${UPDATE}${SUCCESS_SUFFIX}`]: mergePatientInState,
  [`${GET_BY_ID}${REQUEST_SUFFIX}`]: (state, action) => {
    const { id } = action.payload.request
    const { deferredCreate } = createDeferredReducer(id)
    return deferredCreate(state, action)
  },
  [`${GET_BY_ID}${FAILURE_SUFFIX}`]: (state, action) => {
    const { id } = action.payload.request
    const { deferredFailure } = createDeferredReducer(id)
    return deferredFailure(state, action)
  },
  [`${GET_BY_ID}${SUCCESS_SUFFIX}`]: (state, action) => {
    const { id } = action.payload.request
    const { deferredSuccess } = createDeferredReducer(id)
    let newState = deferredSuccess(state, action)
    newState = mergePatientInState(newState, action)
    return newState
  },
  [`${GET_ALL}${REQUEST_SUFFIX}`]: deferredCreate,
  [`${GET_ALL}${FAILURE_SUFFIX}`]: deferredFailure,
  [`${GET_ALL}${SUCCESS_SUFFIX}`]: (state, action) => {
    let newState = deferredSuccess(state, action)
    newState = newState.set('isAllFetched', true)
    newState = mergePatientInState(newState, action)
    return newState
  },
  [`${REMOVE}${SUCCESS_SUFFIX}`]: (state, action) => {
    const { id } = action.payload.request
    return state.removeIn(['all', id])
  },
  // external actions
  // Treatments Actions
  [`${treatmentActions.GET_ALL_IN_PROGRESS}${SUCCESS_SUFFIX}`]: mergePatientInState,
})
