import { fetchRequest } from '../fetchUtil'

import { userArray } from '../../schemas/users'

export const UPDATE_PASSWORD = '@@auth/UPDATE_PASSWORD'
export const LOGIN = '@@auth/LOGIN'
export const LOGOUT = '@@auth/LOGOUT'
export const SIGNUP = '@@auth/SIGNUP'
export const GET_CURRENT_SESSION = '@@auth/GET_CURRENT_SESSION'
export const VERIFY_ACCOUNT = '@@auth/VERIFY_ACCOUNT'
export const CREATE_PASSWORD = '@@auth/CREATE_PASSWORD'
export const FORGOT_PASSWORD = '@@auth/FORGOT_PASSWORD'
export const GET_ALL = '@@auth/GET_ALL'

const API = '/api/auth'

export const login = data => ({
  types: LOGIN,
  payload: { request: data },
  meta: {
    fetch: fetchRequest.bind(null, `${API}/login_with_email_password`, {
      body: JSON.stringify(data),
      method: 'POST',
    }),
  },
})

export const signup = ({ email, stripeTokenId, name }) => ({
  types: SIGNUP,
  payload: { request: { email, stripeTokenId, name } },
  meta: {
    fetch: fetchRequest.bind(null, `${API}/signup`, {
      body: JSON.stringify({ email, stripeTokenId, name }),
      method: 'POST',
    }),
    notification: {
      success: {
        title: 'Cuenta',
        content: 'Te enviamos un email para confirmar tu cuenta.',
      }
    }
  },
})

export const signup2 = data => ({
  types: SIGNUP,
  payload: { request: data },
  meta: {
    fetch: fetchRequest.bind(null, `${API}/create_user`, {
      body: JSON.stringify(data),
      method: 'POST',
    }),
  },
})

export const forgotPassword = data => ({
  types: FORGOT_PASSWORD,
  payload: { request: data },
  meta: {
    fetch: fetchRequest.bind(null, `${API}/forgot_password`, {
      body: JSON.stringify(data),
      method: 'POST',
    }),
  },
})


export const logout = () => ({
  types: LOGOUT,
  meta: {
    fetch: fetchRequest.bind(null, `${API}/logout`, { method: 'POST' }),
  },
})

export const updatePassword = data => ({
  types: UPDATE_PASSWORD,
  meta: {
    fetch: fetchRequest.bind(null, `${API}/update_password`, {
      body: JSON.stringify(data),
      method: 'PATCH',
    }),
  },
})

export const createPassword = data => ({
  types: CREATE_PASSWORD,
  payload: { request: data },
  meta: {
    fetch: fetchRequest.bind(null, `${API}/create_password`, {
      body: JSON.stringify(data),
      method: 'POST',
    }),
    notification: {
      success: {
        title: 'Contraseña',
        content: 'Tu nueva contraseña há sido creada.',
      },
    },
  },
})

export const getCurrentSession = () => ({
  types: GET_CURRENT_SESSION,
  meta: {
    fetch: fetchRequest.bind(null, `${API}/get_current_session`),
  },
})

export const verifyAccount = data => ({
  types: VERIFY_ACCOUNT,
  payload: { request: data },
  meta: {
    fetch: fetchRequest.bind(null, `${API}/verify_account`, {
      body: JSON.stringify(data),
      method: 'PATCH',
    }),
  },
})

export const getAll = () => ({
  types: GET_ALL,
  meta: {
    fetch: fetchRequest.bind(null, `${API}/all`),
    normalize: userArray,
  },
})
