import React from 'react'
import OneOfChip from './oneOffChip'
import { default as _PatientChip } from './patientChip'
import { default as _SessionsBalanceChip } from './sessionsBalanceChip'

export const ONE_OFF_CHIP = 'ONE_OF_CHIP'

export const renderChip = chipName => {
    switch(chipName) {
        case ONE_OFF_CHIP: return <OneOfChip />
        default: return <small>{`Chip not found: ${chipName}`}</small>
    }
}

export const PatientChip = _PatientChip
export const SessionsBalanceChip = _SessionsBalanceChip
