import React from 'react'

import AllTreatmentsContainer from '../containers/treatments/AllTreatmentsContainer'
import MasterPage from './MasterPage'

const TreatmentsPage = (props) => (
  <MasterPage title=''>
    <AllTreatmentsContainer />
  </MasterPage>
)

export default TreatmentsPage
