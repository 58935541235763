import React from 'react'
import PropTypes from 'prop-types'
import ButtonLink from './ButtonLink'

import './button.scss'

const DeleteIconButton = ({ className, ...rest }) => (
    <ButtonLink className={`${className} icon-button`} {...rest}>❌</ButtonLink>
)

DeleteIconButton.propTypes = {
    className: PropTypes.string,
}

export default DeleteIconButton
