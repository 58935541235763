import Immutable from 'immutable'
import { createReducer } from 'redux-immutablejs'

import createDeferredReducer from '../utils/createDeferredReducer'
import {
  FINISH,
  GET_ALL_BY_PATIENT,
    GET_ALL_IN_PROGRESS,
    GET_IN_PROGRESS_BY_PATIENT, GET_TREATMENT_BY_ID, REOPEN, UPDATE,
} from './actions'

import { SUCCESS_SUFFIX, REQUEST_SUFFIX, FAILURE_SUFFIX } from '../utils/promiseMiddleware'

const initialState = Immutable.fromJS({
  all: {},
  treatmentsInProgressByPatient: {}, // <patient id>: [treatments ids in progress]
  deferreds: {},
  treatmentSelected: null,
})

function mergeTreatmentInState(state, action) {
  return state.mergeIn(['all'], Immutable.fromJS(action.payload.response.entities.treatment))
}

export default createReducer(initialState, {
//   [`${ADD}${SUCCESS_SUFFIX}`]: mergeTreatmentInState,
//   [`${DUPLICATE}${SUCCESS_SUFFIX}`]: mergeTreatmentInState,
  [`${UPDATE}${SUCCESS_SUFFIX}`]: mergeTreatmentInState,
  [`${REOPEN}${SUCCESS_SUFFIX}`]: mergeTreatmentInState,
  [`${FINISH}${SUCCESS_SUFFIX}`]: mergeTreatmentInState,
//   [`${GET_ALL}${SUCCESS_SUFFIX}`]: mergeTreatmentInState,
//   [`${GET_BY_DATE}${SUCCESS_SUFFIX}`]: mergeTreatmentInState,
  [`${GET_IN_PROGRESS_BY_PATIENT}${REQUEST_SUFFIX}`]: (state, action) => {
    const {
      deferredCreate,
    } = createDeferredReducer(action.payload.request.patientId)
    return deferredCreate(state, action)
  },
  [`${GET_IN_PROGRESS_BY_PATIENT}${FAILURE_SUFFIX}`]: (state, action) =>{
    const {
      deferredFailure,
    } = createDeferredReducer(action.payload.request.patientId)
    return deferredFailure(state, action)
  },
  [`${GET_IN_PROGRESS_BY_PATIENT}${SUCCESS_SUFFIX}`]: (state, action) => {
    const {
      deferredSuccess,
    } = createDeferredReducer(action.payload.request.patientId)
    let newState = deferredSuccess(state, action)
    const { patientId } = action.payload.request
    const { result: treatmentsIds } = action.payload.response
    newState = newState
        .setIn(['treatmentsInProgressByPatient', patientId], Immutable.fromJS(treatmentsIds))
    newState = mergeTreatmentInState(newState, action)
    return newState
  },

  [`${GET_ALL_IN_PROGRESS}${SUCCESS_SUFFIX}`]: (state, action) => {
    const { result: treatmentsIds } = action.payload.response
    let newState = state
        .set('allInProgressIds', Immutable.fromJS(treatmentsIds))
    newState = mergeTreatmentInState(newState, action)
    return newState
  },

  [`${GET_ALL_BY_PATIENT}${REQUEST_SUFFIX}`]: (state, action) => {
    const {
      deferredCreate,
    } = createDeferredReducer(action.payload.request.patientId)
    return deferredCreate(state, action)
  },
  [`${GET_ALL_BY_PATIENT}${FAILURE_SUFFIX}`]: (state, action) =>{
    const {
      deferredFailure,
    } = createDeferredReducer(action.payload.request.patientId)
    return deferredFailure(state, action)
  },
  [`${GET_ALL_BY_PATIENT}${SUCCESS_SUFFIX}`]: (state, action) => {
    const {
      deferredSuccess,
    } = createDeferredReducer(action.payload.request.patientId)
    let newState = deferredSuccess(state, action)

    return mergeTreatmentInState(newState, action)
  },

  [`${GET_TREATMENT_BY_ID}${REQUEST_SUFFIX}`]: (state, action) => {
    const {
      deferredCreate,
    } = createDeferredReducer(action.payload.request.treatmentId)
    return deferredCreate(state, action)
  },
  [`${GET_TREATMENT_BY_ID}${FAILURE_SUFFIX}`]: (state, action) =>{
    const {
      deferredFailure,
    } = createDeferredReducer(action.payload.request.treatmentId)
    return deferredFailure(state, action)
  },
  [`${GET_TREATMENT_BY_ID}${SUCCESS_SUFFIX}`]: (state, action) => {
    const {
      deferredSuccess,
    } = createDeferredReducer(action.payload.request.treatmentId)
    let newState = deferredSuccess(state, action)
    newState = mergeTreatmentInState(newState, action)
    return newState
  },

//   [`${REMOVE}${SUCCESS_SUFFIX}`]: (state, action) => {
//     const { id } = action.payload.request
//     return state.removeIn(['all', id])
//   },
  // [`${appointmentActions.REMOVE}${SUCCESS_SUFFIX}`]: (action, state) => {
  //   // when an appointment is removed we need to remove it from the treatment state
  //   const { payload: { request: { id } } } = action
  //   const allTreatments = state.get('all')
  //   const filterRemovedAppointment = allTreatments.filter(treatment => treatment.get(''))
  // },
})
