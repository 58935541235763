import Immutable from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { GET, ADD, GET_ALL } from './actions'
import { SUCCESS_SUFFIX } from '../utils/promiseMiddleware'

const initialState = Immutable.fromJS({
  all: Immutable.Map(),
  current: null,
})

function mergeBusinessInState(state, action) {
  return state.mergeIn(['all'], Immutable.fromJS(action.payload.response.entities.business))
}

export default createReducer(initialState, {
  [`${ADD}${SUCCESS_SUFFIX}`]: mergeBusinessInState,
  [`${GET}${SUCCESS_SUFFIX}`]: (state, action) => {
    return state.set('current', Immutable.fromJS(action.payload.response.entities.business[action.payload.response.result]))
  },
  [`${GET_ALL}${SUCCESS_SUFFIX}`]: mergeBusinessInState,
})
