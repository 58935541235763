import React from 'react'
import Immutable from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import ScrollableList from './ScrollableList'
import * as appointmentActions from '../../redux/appointments/actions'
import * as appointmentUtils from '../../utils/appointments'

const AppointmentsList = (props) => {
    const handleDeleteAppointment = id => {
        const { dispatchRemoveAppointment } = props
        const answer = window.confirm('Estas seguro que quieres borrar este turno?')
        if (answer === true) {
            dispatchRemoveAppointment(id)
        }
    }

    const { appointments } = props
    const listItems = appointmentUtils.getListOrderedByStartDate(appointments)
    return (
        <div>
            <div className='dialog-title'><h3>Turnos</h3><h4>Total: {listItems.length}</h4></div>
            <div className='dialog-title-notes'>
                <small>
                    {appointmentUtils.PAST_AAPOINTMENT_ICON} Pasado.
                    {appointmentUtils.FUTURE_APPOINTMENT_ICON} Futuro.
                </small>
            </div>
            <ScrollableList
                items={listItems}
                onDelete={handleDeleteAppointment}
                noRowsMessage='No hay turnos.'
            />
        </div>
    )
}

AppointmentsList.defaultProps = {
    appointments: Immutable.Map(),
}

AppointmentsList.propTypes = {
    appointments: ImmutablePropTypes.map,
    dispatchRemoveAppointment: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
    dispatchRemoveAppointment: id => dispatch(appointmentActions.remove(id)),
})

export default connect(null, mapDispatchToProps)(AppointmentsList)
