import React from 'react'
import PropTypes from 'prop-types'
import Chip from '@material-ui/core/Chip'
import DescriptionIcon from '@material-ui/icons/Description'

import './styles.scss'

const SessionsBalanceChip = ({ number }) => (
    <Chip
        variant={number >= 0 ? 'outlined' : 'default'}
        icon={<DescriptionIcon />}
        label={`Sesiones: ${number}`}
        color={number >= 0 ? 'primary' : 'default'}
        className={number < 0 && 'warning-chip'}
    />
)

SessionsBalanceChip.propTypes = {
    number: PropTypes.number,
}

export default SessionsBalanceChip
