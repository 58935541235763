export const OPEN = '@@dialog/OPEN'
export const CLOSE = '@@dialog/CLOSE'

export const open = (name, data) => ({
  type: OPEN,
  payload: { name, data },
})

export const close = (name) => ({
  type: CLOSE,
  payload: { name },
})
