import { combineReducers } from 'redux-immutablejs'
import { loadingBarReducer } from 'react-redux-loading-bar'
import { reducer as toastrReducer } from 'react-redux-toastr'

import auth from './auth/reducers'
import appointments from './appointments/reducers'
import patients from './patients/reducers'
import business from './business/reducers'
import dialogs from './dialogs/reducers'
import subscription from './subscription/reducers'
import timeRow from './timeRow/reducers'
import treatments from './treatments/reducers'

const rootReducer = combineReducers({
  auth,
  patients,
  appointments,
  treatments,
  business,
  dialogs,
  subscription,
  timeRow,
  loadingBar: loadingBarReducer,
  toastr: toastrReducer,
})

export default rootReducer
