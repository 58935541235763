import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import { ImmutableLoadingBar as LoadingBar } from 'react-redux-loading-bar'
import ReduxToastr from 'react-redux-toastr'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'

import configureStore from './store/configureStore'
import './index.css'
import * as serviceWorker from './serviceWorker'
import Routes from './routes'
import theme from './MaterialUITheme'

const store = configureStore()

ReactDOM.render(<Provider store={store}>
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <LoadingBar style={{ position: 'fixed', bottom: '0', width: '100vw', zIndex: 999, height: '15px', backgroundColor: '#36AB91' }} />
      <Routes />
      <ReduxToastr
        timeOut={8000}
        newestOnTop={false}
        preventDuplicates
        position="bottom-right"
        getState={(state) => state.get('toastr')}
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </ThemeProvider>
  </BrowserRouter>
</Provider>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
