import Immutable from 'immutable'
import { createReducer } from 'redux-immutablejs'

import createDeferredReducer from '../utils/createDeferredReducer'
import {
  GET_BY_DATE,
  SET_CURRENT_DATE,
  REMOVE,
  UPDATE,
  ADD,
  GET_ALL,
  GET_ALL_BY_PATIENT,
  SET_CURRENT_APPOINTMENT_TIME,
  SET_APPOINTMENT_TO_COPY,
  DUPLICATE,
} from './actions'
import { GET_TREATMENT_BY_ID } from '../treatments/actions'
import { SUCCESS_SUFFIX, REQUEST_SUFFIX, FAILURE_SUFFIX } from '../utils/promiseMiddleware'

const initialState = Immutable.fromJS({
  all: Immutable.Map(),
  deferreds: {},
  fetchedByPatient: {},
})

function mergeAppointmentInState(state, action) {
  return state.mergeIn(['all'], Immutable.fromJS(action.payload.response.entities.appointment))
}

export default createReducer(initialState, {
  [`${ADD}${SUCCESS_SUFFIX}`]: mergeAppointmentInState,
  [`${DUPLICATE}${SUCCESS_SUFFIX}`]: mergeAppointmentInState,
  [`${UPDATE}${SUCCESS_SUFFIX}`]: mergeAppointmentInState,
  [`${GET_ALL}${SUCCESS_SUFFIX}`]: mergeAppointmentInState,
  [`${GET_BY_DATE}${SUCCESS_SUFFIX}`]: mergeAppointmentInState,
  [`${GET_ALL_BY_PATIENT}${REQUEST_SUFFIX}`]: (state, action) => {
    const {
      deferredCreate,
    } = createDeferredReducer(action.payload.request.patientId)
    return deferredCreate(state, action)
  },
  [`${GET_ALL_BY_PATIENT}${FAILURE_SUFFIX}`]: (state, action) =>{
    const {
      deferredFailure,
    } = createDeferredReducer(action.payload.request.patientId)
    return deferredFailure(state, action)
  },
  [`${GET_ALL_BY_PATIENT}${SUCCESS_SUFFIX}`]: (state, action) => {
    const {
      deferredSuccess,
    } = createDeferredReducer(action.payload.request.patientId)
    let newState = deferredSuccess(state, action)
    const { patientId } = action.payload.request
    newState = newState.setIn(['fetchedByPatient', patientId], true)
    newState = mergeAppointmentInState(newState, action)
    return newState
  },
  [`${REMOVE}${SUCCESS_SUFFIX}`]: (state, action) => {
    const { id } = action.payload.request
    return state.removeIn(['all', id])
  },
  [SET_CURRENT_DATE]: (state, action) => state.set('currentDate', new Date(action.payload.date)),
  [SET_CURRENT_APPOINTMENT_TIME]: (state, action) => state.set('currentAppointmentTime', action.payload.date),
  [SET_APPOINTMENT_TO_COPY]: (state, action) => state.set('appointmentToCopy', action.payload.originalAppointment),

  [`${GET_TREATMENT_BY_ID}${SUCCESS_SUFFIX}`]: (state, action) => {
    // when we fetch a treatment by id it brings its appointments in the response.
    // so we need to merge them in the state here
    return mergeAppointmentInState(state, action)
  },
})
