
import defer from './defer'

export default function createDeferredReducer(key) {
  return {
    deferredCreate(state, action) {
      return state.setIn(['deferreds', key], defer())
    },
    deferredSuccess(state, action) {
      if (state.getIn(['deferreds', key])) {
        state.getIn(['deferreds', key]).resolve(action.payload)
      }
      return state.deleteIn(['deferreds', key])
    },
    deferredFailure(state, action) {
      if (state.getIn(['deferreds', key])) {
        state.getIn(['deferreds', key]).reject(action.payload)
        return state
          .set('lastError', action.error)
          .deleteIn(['deferreds', key])
      }
      return state
    },
  }
}
