import React from 'react'
import PropTypes from 'prop-types'

import { renderTextField } from '../utils'
import FormHoc from '../../../hocs/FormHoc'
import Button from '../../button'

import '../styles.scss'

const TreatmentForm = ({ fields, handleChange, handleSubmit, disabled }) => {

  const onSubmit = (event) => {
    event.preventDefault()
    const { name } = fields
    if(!name ||  name.trim() === '') {
      alert('Ingrese un nombre para el tratamiento')
      return
    }
    handleSubmit(fields)
  }

  return (
    <form onSubmit={onSubmit} className='form-container'>
      <div>
        {renderTextField({
          label: 'Nombre',
          name: 'name',
          fullWidth: true,
          defaultValue: fields.name,
          onChange: handleChange,
          disabled,
        })}
      </div>
      <div>
        {renderTextField({
          label: 'Cargar Sesiones',
          name: 'newOrdersAmount',
          fullWidth: false,
          type: 'number',
          onChange: handleChange,
          disabled,
        })}
      </div>

      <div className='form-row'>
        <Button disabled={disabled} type="submit">Guardar</Button>
      </div>

    </form>
  )
}

TreatmentForm.propTypes = {
  fields: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default FormHoc(TreatmentForm)
