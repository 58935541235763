import Immutable from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { GET_INFO, GET_STRIPE_KEY, CHARGE, CREATE_CHECKOUT_SESSION } from './actions'
import { SUCCESS_SUFFIX } from '../utils/promiseMiddleware'

const initialState = Immutable.fromJS({
  current: Immutable.Map(),
})


export default createReducer(initialState, {
  [`${GET_INFO}${SUCCESS_SUFFIX}`]: (state, action) => {
    return state
  },
  [`${CHARGE}${SUCCESS_SUFFIX}`]: (state, action) => {
    const payment = action.payload.response
    return state.set('payment', Immutable.fromJS(payment))
  },
  [`${CREATE_CHECKOUT_SESSION}${SUCCESS_SUFFIX}`]: (state, action) => {
    const checkoutSession = action.payload.response
    return state.set('checkoutSession', Immutable.fromJS(checkoutSession))
  },
  // [`${SUBSCRIBE}${SUCCESS_SUFFIX}`]: (state, action) => {
  //   const subscription = action.payload.response
  //   return state.set('subscription', Immutable.fromJS(subscription))
  // },
  [`${GET_STRIPE_KEY}${SUCCESS_SUFFIX}`]: (state, action) => {
    const { key } = action.payload.response
    return state.set('key', key)
  },
  // [`${GET_CUSTOMER}${SUCCESS_SUFFIX}`]: (state, action) => {
  //   const customer = action.payload.response
  //   return state.set('customer', Immutable.fromJS(customer))
  // },
})
