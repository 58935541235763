import React from 'react'
import ImmutablerPropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'

import Link from '../layout/Link'
import styles from './NavBar.module.css'

class NavBar extends React.Component {
  constructor() {
    super()
    this.state = { anchorEl: null }
  }

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { currentUser, dispatchLogout } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)
    return (<AppBar position='static' color='primary' className={styles.menu}>
      <Toolbar>
        <span className={styles.title}>
          <Link to='/'>Turnero</Link>
        </span>
        {currentUser ? (
          <div>
            <Link to='/'>Turnos</Link>
            <Link to='/patients'>Pacientes</Link>
            <Link to='/treatments'>Tratamientos</Link>
            <Link to='/help'>Ayuda</Link>
            <IconButton
              aria-owns={open ? 'menu-appbar' : null}
              aria-haspopup='true'
              onClick={this.handleMenu}
              color='inherit'
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={this.handleClose}
            >
              <Link to='/logout'><MenuItem onClick={dispatchLogout}>Cerrar Sesion</MenuItem></Link>
            </Menu>
          </div>
        ) : (
          <div className={styles.menuLinks}>
            <Link to='/help'>Ayuda</Link>
            <Link to='/login'>LOGIN</Link>
          </div>
        )}
      </Toolbar>
    </AppBar>)
  }
}

NavBar.propTypes = {
  currentUser: ImmutablerPropTypes.map,
  dispatchLogout: PropTypes.func.isRequired,
}
export default NavBar
