import React from 'react'
import { ImmutableLoadingBar as LoadingBar } from 'react-redux-loading-bar'

class Loading extends React.Component {
  constructor() {
    super()
    this.state = {
      completed: 0,
      buffer: 10,
    }
  }

  componentDidMount() {
    this.timer = setInterval(this.progress, 500)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  progress = () => {
    const { completed } = this.state
    if (completed > 100) {
      this.setState({ completed: 0 })
    } else {
      const diff = Math.random() * 10
      this.setState({ completed: completed + diff })
    }
  }

  render() {
    const { completed } = this.state
    return  <LoadingBar value={completed} style={{ position: 'fixed', bottom: '0', width: '100vw', zIndex: 999, height: '15px', backgroundColor: '#36AB91' }} />
  }
}

export default Loading
