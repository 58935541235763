import { createSelector } from 'reselect'

export const getAppointments = state => state.get('appointments')

export const getAll = createSelector(
  getAppointments,
  appointments => appointments.get('all')
)

export const getCurrentDate = createSelector(
  getAppointments,
  appointments => appointments.get('currentDate')
)

export const getAppointmentsFetched = createSelector(
  getAppointments,
  appointments => appointments.get('fetchedByPatient')
)

export const getDeferreds = createSelector(
  getAppointments,
  (appointments) => appointments.get('deferreds')
)

export const getCurrentAppointmentTime = createSelector(
  getAppointments,
  (appointments) => appointments.get('currentAppointmentTime')
)

export const getAppointmentToCopy = createSelector(
  getAppointments,
  (appointments) => appointments.get('appointmentToCopy')
)
