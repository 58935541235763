import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import DialogContent from '@material-ui/core/DialogContent'

import AppointementList from '../../list/AppointmentsList'
import ScrollableList from '../../list/ScrollableList'
import Dialog from '../index'
import TreatmentForm from '../../form/treatment'
import * as treatmentUtils from '../../../utils/treatments'
import './TreatmentDialog.scss'
import MyButton from '../../button'
import PatientChip from '../../chips/patientChip'

const TreatmentDialog = ({
  handleCloseDialog, isDialogOpened, handleSubmit, treatmentSelected, treatmentAppointments, handleDeleteOrders, handleFinishTreatment, handleReopenTreatment, patient,
}) => {
  if(!treatmentSelected) return null
  const totalOrders = treatmentSelected.get('totalOrders')
  const ordersBalance = treatmentSelected.get('ordersBalance')
  let totalOrdersClass = ''
  if(ordersBalance > 0) { totalOrdersClass = 'positive-balance' }
  if(ordersBalance < 0) { totalOrdersClass = 'negative-balance' }
  const isTreatmentCompleted = treatmentSelected.get('completed')

  return (
    <Dialog
      onClose={handleCloseDialog}
      maxWidth='sm'
      open={isDialogOpened}
      labelBy='new-treatment'
      title='Tratamiento'
      actions={[
        isTreatmentCompleted
          ? <MyButton size='small' onClick={handleReopenTreatment}>Abrir Tratamiento</MyButton>
          : <MyButton size='small' onClick={handleFinishTreatment}>Finalizar Tratamiento</MyButton>
      ]}
    >
      <DialogContent dividers>
        {isTreatmentCompleted && (
          <b style={{ fontSize: '18px' }}>
            {`${treatmentUtils.TREATMENT_COMPLETED_ICON} Este tratamiento esta finalizado.`}
          </b>
        )}
        <PatientChip patient={patient} />
        <TreatmentForm
          handleSubmit={handleSubmit}
          closeDialog={handleCloseDialog}
          currentTreatment={treatmentSelected}
          defaultFields={treatmentSelected ? treatmentSelected.toJS() : null}
          disabled={isTreatmentCompleted}
        />

        <div className='vertical-margin'>
          <div className='dialog-title'>
            <h3>Sesiones</h3>
            <h4 className={totalOrdersClass}>Balance: {ordersBalance}</h4>
            <h4 className={totalOrdersClass}>Total: {totalOrders}</h4>
          </div>
          {treatmentSelected && (
            <div>
              <ScrollableList
                items={treatmentUtils.getOrdersLoadedByDate(treatmentSelected.get('orders'))}
                onDelete={handleDeleteOrders}
                noRowsMessage='No se han cargado sesiones para este tratamiento.'
              />
            </div>
          )}
        </div>

        <AppointementList appointments={treatmentAppointments} />
      </DialogContent>
    </Dialog>
  )
}

TreatmentDialog.propTypes = {
  isDialogOpened: PropTypes.bool,
  treatmentSelected: ImmutablePropTypes.map,
  treatmentAppointments: PropTypes.array,

  handleDeleteOrders: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleFinishTreatment: PropTypes.func.isRequired,
  handleReopenTreatment: PropTypes.func.isRequired,
}

export default TreatmentDialog
