import { fetchRequest } from '../fetchUtil'

import { timeRow, timeRowArray } from '../../schemas/timeRow'
export const UPDATE = '@@timeRow/UPDATE'
export const GET_BY_DATE = '@@timeRow/GET_BY_DATE'
export const GET_TIME_TAGS = '@@timeRow/GET_TIME_TAGS'

const API = '/api/timeRow'

export const getByDate = (startDate, endDate) => ({
    types: GET_BY_DATE,
    payload: { startDate, endDate },
    meta: {
      fetch: fetchRequest.bind(null, `${API}/${encodeURIComponent(startDate)}/${encodeURIComponent(endDate)}`),
      normalize: timeRowArray,
    }
})

export const update = data => ({
  types: UPDATE,
  payload: { request: data },
  meta: {
    fetch: fetchRequest.bind(null, API, {
      body: JSON.stringify(data),
      method: 'PUT',
    }),
    normalize: timeRow,
  },
})
