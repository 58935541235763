import { schema } from 'normalizr'

export const timeRow = new schema.Entity('timeRow', {}, {
    idAttribute: '_id',
    processStrategy: (obj) => {
      return {
        ...obj,
        // This can contain a bug if the appointment is saved in one timezone and then the agenda is opened in another timezone
        // but that is not supported by this app. Fix could be asking for preferred timezone and set it here
        date: new Date(obj.date), // Transofrming the UTC date coming from the server to the users locale
      }
    },
  })

export const timeRowArray = new schema.Array(timeRow)
