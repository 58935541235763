import Immutable from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { UPDATE, GET_BY_DATE } from './actions'
import { SUCCESS_SUFFIX } from '../utils/promiseMiddleware'

const initialState = Immutable.fromJS({
  all: Immutable.Map(),
})

function mergeTimeRow(state, action) {
  return state.mergeIn(['all'], Immutable.fromJS(action.payload.response.entities.timeRow))
}

export default createReducer(initialState, {
  [`${UPDATE}${SUCCESS_SUFFIX}`]: mergeTimeRow,
  [`${GET_BY_DATE}${SUCCESS_SUFFIX}`]: mergeTimeRow,
})
