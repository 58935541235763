import moment from 'moment'
require('moment/locale/es')

export const formatDate = (date, format) => {
  if (date && moment(date).isValid()) {
    return moment(date).format(format)
  }
  return 'Not a date'
}

export const formatSimpleDate = (date) => formatDate(date, 'DD/MM/YYYY')
export const parseDate = (date) => moment(date, 'DD/MM/YYYY', true).format()
export const formatDateAndTime = (date) => formatDate(date, 'ddd DD MMM YYYY HH:mm')
export const getCurrentDate = () => moment()
export const setTime = (date, hours = 0, minutes = 0) => moment(date).hours(hours).minutes(minutes)
export const isDateEqual = (a, b) => formatSimpleDate(a) === formatSimpleDate(b)
export const isDateAndTimeEqual = (a, b) => formatDateAndTime(a) === formatDateAndTime(b)
export const isTimeEqual = (a, b) => {
  const dateA = moment(a)
  const dateB = moment(b)
  return dateA.hour() === dateB.hour() && dateA.minute() === dateB.minute()
}
export const formatTime = (date) => {
  return formatDate(date, 'HH:mm')
}

export const addMinutes = (date, amount) => {
  if(date) {
    return moment(date).add(amount, 'minutes')
  }
  return 'Error'
}

export const isPastDate = (date) => {
  return moment().isAfter(date)
}
