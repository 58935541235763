import { fetchRequest } from '../fetchUtil'
import { patient, patientArray } from '../../schemas/patients'

import * as selectors from './selectors'

const API = '/api/patients'

export const GET_ALL = '@@patients/GET_ALL'
export const getAll = () => ({
  types: GET_ALL,
  meta: {
    fetch: fetchRequest.bind(null, API),
    normalize: patientArray,
    shouldFetch: (state) => {
      const deferreds = selectors.getDeferreds(state)
      if (deferreds.has('patients')) {
        return deferreds.get('patients').promise
      }
      if (selectors.isAllFetched(state)) {
        return Promise.resolve(selectors.getAll(state))
      }
      return undefined
    },
  }
})

export const GET_BY_ID = '@@patients/GET_BY_ID'
export const getById = (id) => ({
  types: GET_BY_ID,
  payload: { request: { id } },
  meta: {
    fetch: fetchRequest.bind(null, `${API}/${id}`),
    normalize: patient,
    shouldFetch: (state) => {
      const deferreds = selectors.getDeferreds(state)
      if (deferreds.has(id)) {
        return deferreds.get(id).promise
      }
      const patients = selectors.getAll(state)
      if (patients && patients.has(id)) {
        return Promise.resolve(patients.get(id))
      }
      return undefined
    },
  }
})

export const ADD = '@@patients/ADD'
export const add = data => ({
  types: ADD,
  payload: { request: data },
  meta: {
    fetch: fetchRequest.bind(null, API, {
      body: JSON.stringify(data),
      method: 'POST',
    }),
    normalize: patient,
  },
})

export const UPDATE = '@@patients/UPDATE'
export const update = data => ({
  types: UPDATE,
  payload: { request: data },
  meta: {
    fetch: fetchRequest.bind(null, API, {
      body: JSON.stringify(data),
      method: 'PATCH',
    }),
    notification: {
      success: {
        title: 'Paciente',
        content: 'Paciente actualizado.',
      },
    },
    normalize: patient,
  },
})

export const REMOVE = '@@patients/REMOVE'
export const remove = id => ({
  types: REMOVE,
  payload: { request: { id } },
  meta: {
    fetch: fetchRequest.bind(null, API, {
      body: JSON.stringify({ _id: id }),
      method: 'DELETE',
    }),
    notification: {
      success: {
        title: 'Paciente',
        content: 'Paciente eliminado.',
      },
    },
  },
})
