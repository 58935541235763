import { fetchRequest } from '../fetchUtil'

import { business, businessArray } from '../../schemas/business'
export const ADD = '@@business/ADD'
export const GET_ALL = '@@business/GET_ALL'
export const GET = '@@business/GET_CURRENT'

const API = '/api/business'

export const getAll = () => ({
  types: GET_ALL,
  meta: {
    fetch: fetchRequest.bind(null, API),
    normalize: businessArray,
  }
})

export const getCurrent = () => ({
  types: GET,
  meta: {
    fetch: fetchRequest.bind(null, `${API}/current`),
    normalize: business,
  }
})

export const add = data => ({
  types: ADD,
  payload: { request: data },
  meta: {
    fetch: fetchRequest.bind(null, API, {
      body: JSON.stringify(data),
      method: 'POST',
    }),
    normalize: business,
  },
})
