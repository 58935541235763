export const fetchRequest = (url, options) => {
  return new Promise((resolve, reject) => {
    let apiUrl = window && window._env_ && window._env_.API_HOST
    if(apiUrl) {
      apiUrl = `${window._env_.API_HOST}${url}`
    } else {
      apiUrl = url
    }
    fetch(apiUrl, {
      headers: {
        'content-type': 'application/json',
      },
      credentials: 'include',
      ...options,
    }).then((result) => {
      if (result.status < 200 || result.status >= 300) {
        result.json().then(errorData => {
          reject({ ...errorData, status: result.status })
        })
      } else if(result.status === 204) {
        resolve()
      } else {
        // successful!
        result.json().then((data) => {
          resolve(data)
        })
      }
    }).catch((error) => {
      reject(error)
    })
  })
}
