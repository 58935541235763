import React from 'react'
import PropTypes from 'prop-types'
import InputLabel from '@material-ui/core/InputLabel'

import './label.scss'

const Label = ({ children, addPaddingBottom }) => <InputLabel className={addPaddingBottom && 'label-padding-bottom'}>{children}</InputLabel>

Label.defaultProps = {
    addPaddingBottom: true,
}

Label.propTypes = {
    children: PropTypes.string.isRequired,
    addPaddingBottom: PropTypes.bool,
}

export default Label
