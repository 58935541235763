import Immutable from 'immutable'

import { formatDateAndTime } from './dates'

export const getOrdersLoadedByDate = (orders) => {
    if(orders && orders.count() > 0) {
        return orders.valueSeq()
            .map(order => Immutable.fromJS({
                _id: order.get('_id'),
                title: formatDateAndTime(order.get('date')),
                value: order.get('amount'),
            }))
            .sortBy(order => order.get('date'))
            .reverse()
            .toArray()
    }
    return []
}

export const getTreatmentsByDate = treatments => {
    if(treatments && treatments.count() > 0) {
        return treatments.valueSeq()
            .sortBy(treatment => treatment.get('creationDate'))
            .reverse()
            .toArray()
    }
    return []
}

export const TREATMENT_TYPE_NEW = 'new-treatment'
export const TREATMENT_TYPE_EXISTING = 'existing-treatment'
export const TREATMENT_TYPE_ONE_OFF = 'one-off-appointment'

export const TREATMENT_IN_PROGRESS_ICON = '🤲'
export const TREATMENT_COMPLETED_ICON = '✅'
