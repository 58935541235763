import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'

import './dialog.scss'


const CustomDialog = ({ children, actions, onClose, open, labelBy, maxWidth, title }) => (
  <Dialog
    fullWidth
    maxWidth={maxWidth ? maxWidth : 'sm'}
    onClose={onClose}
    aria-labelledby={labelBy}
    open={open}
    className='dialog-container'
  >
    <DialogTitle id={labelBy} className='dialog-title'>{title}</DialogTitle>
    {children}
    {actions && (
      <DialogActions>
        {actions}
      </DialogActions>
    )}
  </Dialog>
)

CustomDialog.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  labelBy: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  maxWidth: PropTypes.oneOf(['xl', 'md', 'xs']),
  actions: PropTypes.array,
}

export default CustomDialog
