import { schema } from 'normalizr'

export const appointment = new schema.Entity('appointment', {}, {
  idAttribute: '_id',
  processStrategy: (obj, parent, key) => {
    return {
      ...obj,
      // This can contain a bug if the appointment is saved in one timezone and then the agenda is opened in another timezone
      // but that is not supported by this app. Fix could be asking for preferred timezone and set it here
      start: new Date(obj.start), // Transofrming the UTC date coming from the server to the users locale
      end: new Date(obj.end), // Transofrming the UTC date coming from the server to the users locale
    }
  },
})
export const appointmentArray = new schema.Array(appointment)
