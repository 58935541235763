import React from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'

// import * as appointmentActions from '../../redux/appointments/actions'
import * as treatmentActions from '../../redux/treatments/actions'
import * as treatmentSelectors from '../../redux/treatments/selectors'
import * as dialogActions from '../../redux/dialogs/actions'
import * as dialogSelectors from '../../redux/dialogs/selectors'
import * as patientSelectors from '../../redux/patients/selectors'
import TreatmentDialog from '../../components/dialog/TreatmentDialog'

const TreatmentDialogContainer = ({
  open, treatmentId, treatmentSelected, dispatchCloseDialog, dispatchGetTreatmentById, treatmentAppointments, dispatchUpdateTreatment, dispatchFinishTreatment, dispatchReopenTreatment, ...rest
}) => {
  React.useEffect(() => {
    if(treatmentId) {
      dispatchGetTreatmentById(treatmentId)
    }
  }, [treatmentId])

  const prevAppointments = React.useRef()
  prevAppointments.current = treatmentAppointments
  React.useEffect(() => {
    if(prevAppointments.current.count() !== treatmentAppointments.count()) {
      // an appointment has been removed. We fetch treatment again to recalculate sessions balance
      onAppointmentRemoved()
    }
  }, [treatmentAppointments])

  const onCloseDialog = () => {
    dispatchCloseDialog()
  }

  const onAppointmentRemoved = () => {
    if(treatmentId) {
      dispatchGetTreatmentById(treatmentId)
    }
  }

  const handleSubmit = (fields) => {
    if(fields) {
      const treatment = {
        ...treatmentSelected.toJS(),
        name: fields.name,
        orders: fields.newOrdersAmount > 0
          ? treatmentSelected.get('orders').concat([{ amount: fields.newOrdersAmount }]).toJS()
          : treatmentSelected.get('orders'),
      }
      if (fields._id) {
        dispatchUpdateTreatment({ _id: fields._id, ...treatment })
      } else {
        alert('Save new treatment to be implemented')
      }
    }
  }

  const handleDeleteOrders = id => {
    const answer = window.confirm('Estas seguro que quieres borrar estas sesiones?')
    if (answer === true) {
      const treatmentUpdated = treatmentSelected.set('orders', treatmentSelected.get('orders').filter(order => order.get('_id') !== id))
      dispatchUpdateTreatment(treatmentUpdated.toJS())
    }
  }

  const handleFinishTreatment = () => {
    let answer
    if(treatmentSelected.get('ordersBalance') < 0) {
      answer = window.confirm('El balance de sesiones no es suficiente. Desea finalizar el tratamiento de todos modos?')
    } else {
      answer = true
    }
    if(answer && treatmentSelected && treatmentSelected.has('_id')) {
      dispatchFinishTreatment(treatmentSelected.get('_id'))
    }
  }

  const handleReopenTreatment = () => {
    if(treatmentSelected && treatmentSelected.has('_id')) {
      dispatchReopenTreatment(treatmentSelected.get('_id'))
    }
  }

  if(open) {
    return (
      <TreatmentDialog
        isDialogOpened={open}
        treatmentSelected={treatmentSelected}
        treatmentAppointments={treatmentAppointments}
        handleCloseDialog={onCloseDialog}
        handleSubmit={handleSubmit}
        handleDeleteOrders={handleDeleteOrders}
        handleFinishTreatment={handleFinishTreatment}
        handleReopenTreatment={handleReopenTreatment}
        {...rest}
      />
    )
  }
  return null

}

TreatmentDialogContainer.propTypes = {
  treatmentSelected: ImmutablePropTypes.map,
  treamentId: PropTypes.string,
  treatmentAppointments: ImmutablePropTypes.list,
  patient: ImmutablePropTypes.map,

  dispatchCloseDialog: PropTypes.func.isRequired,
  dispatchGetTreatmentById: PropTypes.func.isRequired,
  dispatchUpdateTreatment: PropTypes.func.isRequired,
  dispatchFinishTreatment: PropTypes.func.isRequired,
  dispatchReopenTreatment: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const dialogData = dialogSelectors.getTreatmentDialogData(state)
  let treatmentSelected
  let treatmentAppointments = Immutable.List()
  let treatmentId
  const allTreatments = treatmentSelectors.getAll(state)
  if(dialogData && dialogData.has('treatmentId')) {
    treatmentId = dialogData.get('treatmentId')
    treatmentSelected = allTreatments.get(treatmentId)
    treatmentAppointments = Immutable.List()

    if (treatmentId) {
      treatmentAppointments = treatmentSelectors.getTreatmentAppointments(state, treatmentId)
    }
  }

  const allPatients = patientSelectors.getAll(state)
  let patient
  if(treatmentSelected && allPatients && allPatients.has(treatmentSelected.get('patientId'))) {
    patient = allPatients.get(treatmentSelected.get('patientId'))
  }
  return {
    open: dialogSelectors.isTreatmentDialogOpened(state),
    treatmentSelected,
    treatmentId,
    treatmentAppointments,
    patient,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchCloseDialog: () => dispatch(dialogActions.close('treatment')),
  dispatchGetTreatmentById: id => dispatch(treatmentActions.getById(id)),
  dispatchUpdateTreatment: data => dispatch(treatmentActions.update(data)),
  dispatchFinishTreatment: id => dispatch(treatmentActions.finish(id)),
  dispatchReopenTreatment: id => dispatch(treatmentActions.reopen(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentDialogContainer)
