import { fetchRequest } from '../fetchUtil'

export const GET_INFO = '@@subscription/GET_INFO'
export const SUBSCRIBE = '@@subscription/SUBSCRIBE'
export const GET_STRIPE_KEY = '@@subscription/GET_STRIPE_KEY'
export const CHARGE = '@@subscription/CHARGE'
export const CREATE_CHECKOUT_SESSION = '@@subscription/CREATE_CHECKOUT_SESSION'
const API = '/api/subscription'

export const getInfo = (subscriptionId) => ({
  types: GET_INFO,
  meta: {
    fetch: fetchRequest.bind(null, `${API}/info/${subscriptionId}`),
  }
})

export const createCheckoutSession = () => ({
  types: CREATE_CHECKOUT_SESSION,
  meta: {
    fetch: fetchRequest.bind(null, `${API}/create-checkout-session`, {
      method: 'POST',
    }),
  },
})
export const subscribe = () => ({
  types: SUBSCRIBE,
  meta: {
    fetch: fetchRequest.bind(null, API, {
      method: 'POST',
      body: JSON.stringify({ plan: 'basic' }),
    }),
  }
})

export const charge = data => ({
  types: CHARGE,
  payload: { request: data },
  meta: {
    fetch: fetchRequest.bind(null, `${API}/charge`, {
      body: JSON.stringify(data),
      method: 'POST',
    }),
  },
})

export const getStripeKey = () => ({
  types: GET_STRIPE_KEY,
  meta: {
    fetch: fetchRequest.bind(null, `${API}/stripe-key`),
  },
})
