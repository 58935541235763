import Immutable from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { OPEN, CLOSE } from './actions'
import { ADD as ADD_PATIENT } from '../patients/actions'
import { SUCCESS_SUFFIX } from '../utils/promiseMiddleware'

const initialState = Immutable.fromJS({
  all: Immutable.fromJS({
    appointment: { open: false, data: null },
    patient: { open: false, data: null },
    treatment: { open: false, data: null },
  }),
})


export default createReducer(initialState, {
  [OPEN]: (state, action) => {
    const { name, data } = action.payload
    return state.setIn(['all', name], Immutable.fromJS({ open: true, data }))
  },
  [CLOSE]: (state, action) => {
    const { name } = action.payload
    return state.setIn(['all', name], Immutable.fromJS({ open: false }))
  },
  [`${ADD_PATIENT}${SUCCESS_SUFFIX}`]: (state, action) => {
    // when we add a patient while the appointment dialog is opened we need to select it by default in the dialog.
    // so we set the id in the patient id in the dialog data ONLY if dialog is opened
    if(state.getIn(['all', 'appointment', 'open'])) {
      const patientId = action.payload.response.result
      if(state.getIn(['all', 'appointment', 'data']) && state.getIn(['all', 'appointment', 'data', 'appointment'])) {
        // if there is a patient selected already we set the new patient id in the dialog data
        return state.mergeIn(['all', 'appointment', 'data', 'appointment', 'patient'], patientId)
      } else {
        // of there is no patient selected we create the data and set the new patient id
        return state.mergeIn(['all', 'appointment'], Immutable.fromJS({ data: { appointment: { patient: patientId } } }))
      }
    }
    return state
  },
})
