import React from 'react'
import Immutable from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import ScrollableList from './ScrollableList'
import TreatmentDialogContainer from '../../containers/dialogs/treatment'
import * as treatmentsUtils from '../../utils/treatments'
import * as dialogActions from '../../redux/dialogs/actions'
import TreatmentListItem from './items/TreatmentListItem'

const TreatmentsList = (props) => {
    const handleOpenTreatmentDialog = (treatment) => {
        const { dispatchOpenTreatmentDialog } = props
        dispatchOpenTreatmentDialog({ treatmentId: treatment.get('_id') })
    }

    const { treatments, showPatientChip } = props
    return (
        treatments.count() > 0 ? (
            <div>
                <div className='dialog-title'>
                    <h3>Tratamientos</h3><h4>Total: {treatments.count()}</h4>
                </div>
                <div className='dialog-title-notes'>
                    <small>
                        {treatmentsUtils.TREATMENT_IN_PROGRESS_ICON} En progreso.
                        {treatmentsUtils.TREATMENT_COMPLETED_ICON} Finalizado.
                    </small>
                </div>
                <ScrollableList
                    renderItem={item => <TreatmentListItem showPatientChip={showPatientChip} treatment={item} onClick={handleOpenTreatmentDialog} />}
                    items={treatmentsUtils.getTreatmentsByDate(treatments)}
                    onClick={handleOpenTreatmentDialog}
                />
                <TreatmentDialogContainer />
            </div>
        ): <p>No hay tratamientos.</p>
    )
}

TreatmentsList.defaultProps = {
    treatments: Immutable.Map(),
    showPatientChip: true,
}

TreatmentsList.propTypes = {
    treatments: ImmutablePropTypes.map,
    dispatchOpenTreatmentDialog: PropTypes.func.isRequired,
    showPatientChip: PropTypes.bool,
}

const mapDispatchToProps = dispatch => ({
    dispatchOpenTreatmentDialog: (data) => dispatch(dialogActions.open('treatment', data)),
})

export default connect(null, mapDispatchToProps)(TreatmentsList)
