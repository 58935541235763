import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Chip from '@material-ui/core/Chip'
import Person from '@material-ui/icons/Person'

const PatientChip = ({ patient }) => (
    patient ? <Chip variant='outlined' icon={<Person />} label={patient.get('fullName')} /> : null
)

PatientChip.propTypes = {
    patient: ImmutablePropTypes.map,
}

export default PatientChip
