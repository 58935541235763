import Immutable from 'immutable'

import { formatDateAndTime } from './dates'
import { ONE_OFF_CHIP } from '../components/chips'
/**
 *
 * @param {*} Immutable list  of appointments
 * @returns An array of appointments ordered by start date.
 * { title: <formatted start date>, description: <appointment notes>, start: <full start date>}
 */
export const getListOrderedByStartDate = list => {
    if(list && list.count() > 0) {
        return list.valueSeq()
            .filter(appointment => appointment)
            .map((appointment) => {
                const title = formatDateAndTime(appointment.get('start'))
                return (
                    Immutable.fromJS({
                        _id: appointment.get('_id'),
                        title: `${appointment.get('start') < new Date() ? PAST_AAPOINTMENT_ICON : FUTURE_APPOINTMENT_ICON} ${title}`,
                        description: appointment.get('notes'),
                        chips: appointment.get('oneOffAppointment') ? [ONE_OFF_CHIP] : [],
                        start: appointment.get('start'),
                    })
                )
            })
            .sortBy(appointment => appointment.get('start'))
            .reverse()
            .toArray()
    }
    return []
}

export const FUTURE_APPOINTMENT_ICON = '📗'
export const PAST_AAPOINTMENT_ICON = '📖'
