import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as treatmentActions from '../../redux/treatments/actions'
import * as treatmentSelectors from '../../redux/treatments/selectors'
import TreatmentsList from '../../components/list/TreatmentsList'

const AllTreatmentsContainer = (props) => {
    React.useEffect(() => {
        const { dispatchGetAllInProgress } = props
        dispatchGetAllInProgress()
    }, [])

    const { allTreatmentsInProgress } = props
    if(allTreatmentsInProgress && allTreatmentsInProgress.count() > 0) {
        return <TreatmentsList treatments={allTreatmentsInProgress} />
    }
    return <small>No se encontrarion tratamientos.</small>
}

AllTreatmentsContainer.defaultProps = {
    allTreatmentsInProgress: Immutable.List(),
}

AllTreatmentsContainer.propTypes = {
    dispatchGetAllInProgress: PropTypes.func.isRequired,
    allTreatmentsInProgress: ImmutablePropTypes.list,
}

const mapStateToProps = state => ({
    allTreatmentsInProgress: treatmentSelectors.getAllTreatmentsInProgress(state),
})

const mapDispatchToProps = dispatch => ({
    dispatchGetAllInProgress: () => dispatch(treatmentActions.getAllInProgress()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AllTreatmentsContainer)
