import { fetchRequest } from '../fetchUtil'
import { treatment, treatmentArray } from '../../schemas/treatments'

const API = '/api/treatments'

export const GET_IN_PROGRESS_BY_PATIENT = '@@treatments/GET_ALL_IN_PROGRESS_BY_PATIENT'
export const getInProgressByPatient = (patientId) => ({
    types: GET_IN_PROGRESS_BY_PATIENT,
    payload: { request: { patientId } },
    meta: {
      fetch: fetchRequest.bind(null, `${API}/in_progress/${patientId}`),
      normalize: treatmentArray,
    }
})

export const GET_ALL_IN_PROGRESS = '@@treatments/GET_ALL_IN_PROGRESS'
export const getAllInProgress = () => ({
  types: GET_ALL_IN_PROGRESS,
  meta: {
    fetch: fetchRequest.bind(null, `${API}/in_progress`),
    normalize: treatmentArray,
  }
})


export const GET_ALL_BY_PATIENT = '@@treatments/GET_ALL_BY_PATIENT'
export const getAllByPatient = (patientId) => ({
  types: GET_IN_PROGRESS_BY_PATIENT,
  payload: { request: { patientId } },
  meta: {
    fetch: fetchRequest.bind(null, `${API}/all/${patientId}`),
    normalize: treatmentArray,
  }
})

export const GET_TREATMENT_BY_ID = '@@treatments/GET_TREATMENT_BY_ID'
export const getById = (treatmentId) => ({
  types: GET_TREATMENT_BY_ID,
  payload: { request: { treatmentId } },
  meta: {
    fetch: fetchRequest.bind(null, `${API}/${treatmentId}`),
    normalize: treatment,
  }
})

export const UPDATE = '@@treatments/UPDATE'
export const update = data => ({
  types: UPDATE,
  payload: { request: data },
  meta: {
    fetch: fetchRequest.bind(null, API, {
      body: JSON.stringify(data),
      method: 'PATCH',
    }),
    notification: {
      success: {
        title: 'Tratamiento',
        content: 'Tratamiento actualizado.',
      },
    },
    normalize: treatment,
  },
})

/**
 * Force marking as completed the given treatment
 */
export const FINISH = '@@treatments/FINISH_TREATMENT'
export const finish = treatmentId => ({
  types: FINISH,
  payload: { request: treatmentId },
  meta: {
    fetch: fetchRequest.bind(null, `${API}/finish/${treatmentId}`, {
      method: 'PATCH',
    }),
    notification: {
      success: {
        title: 'Tratamiento',
        content: 'Tratamiento Finalizado.',
      },
    },
    normalize: treatment,
  }
})


/**
 * Force marking as completed=false the given treatment
 */
 export const REOPEN = '@@treatments/REOPEN_TREATMENT'
 export const reopen = treatmentId => ({
   types: REOPEN,
   payload: { request: treatmentId },
   meta: {
     fetch: fetchRequest.bind(null, `${API}/reopen/${treatmentId}`, {
       method: 'PATCH',
     }),
     notification: {
       success: {
         title: 'Tratamiento',
         content: 'El tratamiento esta ahora nuevamente abierto.',
       },
     },
     normalize: treatment,
   }
 })

