import React from 'react'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

import './list.scss'
import DeleteIconButton from '../button/DeleteIconButton'
import { renderChip } from '../chips'

const ScrollableList = ({ items, onClick, onDelete, renderItem, noRowsMessage }) => {
  const renderEmptyList = () => <div className='empty-list-message'>{noRowsMessage}</div>

  const renderList = () => (
    items.map(item => (
      renderItem
        ? renderItem(item)
        : (
          <div className='list-item'>
            <ListItem divider key={item.get('title')}>
              <ListItemText
                className={onClick ? 'clickable-item' : ''}
                primary={item.get('title')}
                secondary={item.get('description')}
                onClick={() => (onClick ? onClick(item.get('_id')) : {})}
              />
              <ListItemSecondaryAction>
                {item.has('chips') && item.get('chips').count() > 0 ? item.get('chips').map(chip => renderChip(chip)) : null}
                <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{item.get('value')} </span>
                {onDelete && <DeleteIconButton className='delete-item-button' onClick={() => onDelete(item.get('_id'))} />}
              </ListItemSecondaryAction>
            </ListItem>
          </div>
        )
    ))
  )

  return (
    <div>
      <List className='list-container'>
        {items && items.length > 0
          ? renderList()
          : renderEmptyList()
        }
      </List>
    </div>
  )
}

ScrollableList.defaultProps = {
  noRowsMessage: 'No hay datos.',
}

ScrollableList.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  onDelete: PropTypes.func,
  renderItem: PropTypes.func,
  noRowsMessage: PropTypes.string, // text to show when list is empty.
}

export default ScrollableList
