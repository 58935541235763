import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import CssBaseline from '@material-ui/core/CssBaseline'

import NavBarContainer from '../containers/NavBarContainer'
import Footer from '../components/layout/Footer'
// actions
import * as authActions from '../redux/auth/actions'
import * as businessActions from '../redux/business/actions'
// selectors
import * as authSelectors from '../redux/auth/selectors'

import styles from './master.module.css'

const MasterPage = ({ children, dispatchGetCurrentSession, dispatchGetCurrentBusiness, location, showNavBar, title, description, currentUser }) => {
  React.useEffect(() => {
    dispatchGetCurrentSession()
  }, [])
  React.useEffect(() => {
    if(currentUser) {
      dispatchGetCurrentBusiness()
    }
  }, [currentUser])
  return (<div>
    {showNavBar && <NavBarContainer location={location} />}
    <CssBaseline />
    <div className={styles.content}>
      {title && <h1>{title}</h1>}
      {description && <p>{description}</p>}
      {children}
    </div>
    <Footer />
  </div>)
}

MasterPage.defaultProps = {
  showNavBar: true,
}

MasterPage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  location: PropTypes.object,
  showNavBar: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  dispatchGetCurrentSession: PropTypes.func.isRequired,
  dispatchGetCurrentBusiness: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  currentUser: authSelectors.getCurrentUser(state),
})

const mapDispatchToProps = dispatch => ({
  dispatchGetCurrentSession: () => dispatch(authActions.getCurrentSession()),
  dispatchGetCurrentBusiness: () => dispatch(businessActions.getCurrent()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MasterPage)
