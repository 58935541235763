import React from 'react'

const FormHoc = (InnerComponent) => {
  class FormWrapper extends React.Component {
    constructor(props) {
        super(props)
        const { defaultFields } = props
        // in order to be able to reset the form we need to initialize all fields with a default vale
        // thats why we need to pass this default fields object
        // more info here https://stackoverflow.com/questions/37427508/react-changing-an-uncontrolled-input
        this.state = defaultFields ? { fields: defaultFields } : { fields: {} }
      }

    componentWillReceiveProps(nextProps) {
      const { defaultFields } = this.props
      if(defaultFields !== nextProps.defaultFields) {
        this.setState({ fields: nextProps.defaultFields })
      }
    }

    handleCheckboxChange = (event) => {
      const { name, checked } = event.target
      const { fields } = this.state
      this.setState({
        fields: { ...fields, [name]: checked },
      })
    }

    handleChange = (event) => {
      const { name, value } = event.target
      const { fields } = this.state
      this.setState({
        fields: { ...fields, [name]: value },
      })
    }
    render() {
      const { fields } = this.state
      return <InnerComponent {...this.props} fields={fields} handleChange={this.handleChange} handleCheckboxChange={this.handleCheckboxChange} />
    }
  }
  return FormWrapper
}

export default FormHoc
