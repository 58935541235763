import { createSelector } from 'reselect'

export const getPatients = state => state.get('patients')

export const getAll = createSelector(
  getPatients,
  patients => patients.get('all')
)

export const isAllFetched = createSelector(
  getPatients,
  (patients) => patients.get('isAllFetched')
)

export const getDeferreds = createSelector(
  getPatients,
  (patients) => patients.get('deferreds')
)
