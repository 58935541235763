import Immutable from 'immutable'
import { createSelector } from 'reselect'

import * as appointmentSelectors from '../appointments/selectors'

const getTreatments = state => state.get('treatments')

export const getAll = createSelector(
  getTreatments,
  treatments => treatments.get('all')
)

const getAllTreatmentsInProgressByPatient = createSelector(
    getTreatments,
    treatments => treatments.get('treatmentsInProgressByPatient')
)

/**
 * Gets all treatments for a given patient id.
 * @param {*} state
 * @param {*} patientId
 * @returns
 */
export const getAllTreatmentsForPatient = (state, patientId) => {
  const allTreatments = getAll(state)
  return allTreatments.filter(t => t.get('patientId') === patientId)
}

/**
 * Gets ids of all treatments in progress
 */
const getTreatmentsInProgressIds = createSelector(
  getTreatments,
  treatments => treatments.get('allInProgressIds')
)

/**
 * Gets all treatments in progress
 */
 export const getAllTreatmentsInProgress = createSelector(
  getTreatmentsInProgressIds,
  getAll,
  (ids, allTreatments) => {
    if(ids && allTreatments) {
      return allTreatments.filter(t => ids.includes(t.get('_id')))
    }
    return null
  },
)

/**
 * This selector returns the treatments in progress for a given patient id.
 * If the patient doesnt have any treatment in progress returns an empty Immutable.List
 *
 * If the size of the treatmentsInProgressByPatient ids is different from the treatments list found returns null.
 * @param state
 * @param patientId
 * @returns Immutable Map  <treatmentId>:<treatment>
 */
export const getTreatmentsInProgressForPatient = (state, patientId) => {
  const allTreatmentsInProgressByPatient = getAllTreatmentsInProgressByPatient(state)
  const allTreatments = getAll(state)
  if (!allTreatmentsInProgressByPatient.has(patientId) || allTreatments.count() === 0) {
    return Immutable.List() // patient doesnt have treatments in progress.
  }

  const idsOfTreatments = allTreatmentsInProgressByPatient.get(patientId)
  const treatments = allTreatments.filter(t => idsOfTreatments.includes(t.get('_id')))

  if(treatments.count() === idsOfTreatments.count()) {
      return treatments
  }
  // ideally we shouldnt ever get to here, as the ids list and actual object of treatments will be the same size.
  return null
}

/**
 * @param {*} state
 * @param {*} treatmentId
 * @returns list with the appointment objects for the given treatment id
 */
export const getTreatmentAppointments = (state, treatmentId) => {
  const appointments = appointmentSelectors.getAll(state)
  const treatments = getAll(state)

  if(treatments.has(treatmentId)) {
    const treatment = treatments.get(treatmentId)
    const appointmentIds = treatment.has('appointments') ? treatment.get('appointments') : Immutable.List()
    return appointmentIds.map(id => appointments.get(id))
  }
  return null
}
