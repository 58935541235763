import { createStore, applyMiddleware, compose } from 'redux'
import { loadingBarMiddleware } from 'react-redux-loading-bar'

import promiseMiddleware from '../redux/utils/promiseMiddleware'
import rootReducer from '../redux/reducers'


function configureStore(initialState) {
  const store = createStore(rootReducer, initialState, compose(
    applyMiddleware(
      promiseMiddleware,
      loadingBarMiddleware({
        promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE'],
      }),
    ),
  ))

  return store
}

export default configureStore
