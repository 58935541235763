import React from 'react'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Label from './Label'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'

export const renderRadioGroup = ({ options, name, value, onChange, disabled }) => (
  <RadioGroup aria-label={name} name={name} value={value} onChange={onChange}>
    {options.map(item => <FormControlLabel key={item.value} value={item.value} control={<Radio disabled={disabled || item.disabled} color='primary' />} label={item.label} />)}
  </RadioGroup>
)

export const renderCheckboxField = ({ label, ...rest}) => (
  <FormControlLabel control={<Checkbox {...rest} />} label={label} />
)

export const renderTextField = props => (
  <TextField {...props} margin="normal" />
)

export const renderEmailField = props => (
  renderTextField({ label: 'Email', type: 'email', name: 'email', ...props })
)

export const renderPasswordField = props => (
  renderTextField({ label: 'Contraseña', type: 'password', name: 'password', ...props })
)

export const renderTextArea = props => (
  renderTextField({ ...props, type: 'textarea', inputProps:{maxLength: '500'}, multiline: true, rows: '3', rowsMax: '6'})
)

/**
 * There are 2 different ways of show the select options.
 * 1- Simple List: just send the array of "options" prop: [{ value, label }, ...]
 * 2- For groupped options send the "groups" prop array: [{ label, options: [{ value, label }, ...]}, ...]
 * grouped options show the group label in the select box, useful for dividing options.
 * @param {*} param0
 * @returns
 */
export const renderSelectBox = ({options, groups, name, label, ...rest}) => {

  const getFormattedOptions = (optionsList) => (
    optionsList.map(option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)
  )

  let formattedOptions
  if(groups) {
    formattedOptions = []
    groups.forEach(group => {
      formattedOptions.push(<ListSubheader>{group.label}</ListSubheader>)
      formattedOptions.push(getFormattedOptions(group.options))
    })
  } else {
    formattedOptions = getFormattedOptions(options)
  }

  return (
    <div>
      <Label>{label}</Label>
      <Select
        className='select-box'
        style={{ width: '100%' }}
        inputProps={{ name }}
        {...rest}
      >
        {formattedOptions}
      </Select>
  </div>
  )
}
