import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

import * as patientSelectors from '../../../redux/patients/selectors'
import { PatientChip, SessionsBalanceChip } from '../../chips'
import { formatDateAndTime } from '../../../utils/dates'
import { TREATMENT_COMPLETED_ICON, TREATMENT_IN_PROGRESS_ICON } from '../../../utils/treatments'

const TreatmentListItem = ({ treatment, patients, onClick, showPatientChip }) => {
    if(!treatment) return null
    const patient = patients ? patients.get(treatment.get('patientId')) : null

    const title = `${treatment.get('completed') ? TREATMENT_COMPLETED_ICON : TREATMENT_IN_PROGRESS_ICON} ${treatment.get('name')}`

    return (
        <ListItem divider key={treatment.get('title')} className={onClick ? 'clickable-item' : ''}>
            <ListItemText
                primary={title}
                secondary={formatDateAndTime(treatment.get('creationDate'))}
                onClick={() => (onClick ? onClick(treatment) : {})}
            />
            <ListItemSecondaryAction>
                <SessionsBalanceChip number={treatment.get('ordersBalance')} />
                {' '}
                {showPatientChip && <PatientChip patient={patient} />}
            </ListItemSecondaryAction>
        </ListItem>
    )
}

TreatmentListItem.defaultProps = {
    patient: true,
}

TreatmentListItem.propTypes = {
    treatment: ImmutablePropTypes.map,
    patients: ImmutablePropTypes.map,
    onClick: PropTypes.func,
    showPatientChip: PropTypes.bool,
}

const mapStateToProps = state => ({
    patients: patientSelectors.getAll(state),
})

export default connect(mapStateToProps)(TreatmentListItem)
