import { fetchRequest } from '../fetchUtil'
import { appointment, appointmentArray } from '../../schemas/appointments'

import * as selectors from './selectors'
export const ADD = '@@appointments/ADD'
export const DUPLICATE = '@@appointments/DUPLICATE'
export const GET_BY_DATE = '@@appointments/GET_BY_DATE'
export const REMOVE = '@@appointments/REMOVE'
export const UPDATE = '@@appointments/UPDATE'
export const GET_ALL = '@@appointments/GET_ALL'
export const SET_CURRENT_DATE = '@@appointments/SET_CURRENT_DATE'
export const GET_ALL_BY_PATIENT = '@@appointments/GET_ALL_BY_PATIENT'
export const SET_CURRENT_APPOINTMENT_TIME = '@@appointments/SET_CURRENT_APPOINTMENT_TIME'
export const SET_APPOINTMENT_TO_COPY = '@@appointments/SET_APPOINTMENT_TO_COPY'

const API = '/api/appointments'

export const add = data => ({
  types: ADD,
  payload: { request: data },
  meta: {
    fetch: fetchRequest.bind(null, API, {
      body: JSON.stringify(data),
      method: 'POST',
    }),
    normalize: appointment,
  },
})

/*
* Duplicates an appointment in another slot
* @args id: Id of the appointment to clone
*       newStartDate: new slot where the appointment will be cloned
*/
export const duplicate = (appointmentToCopy, newStartDate) => ({
  types: DUPLICATE,
  payload: { request: { appointmentToCopy, newStartDate} },
  meta: {
    fetch: fetchRequest.bind(null, `${API}/duplicate/startDate/${newStartDate}`, {
      method: 'POST',
      body: JSON.stringify(appointmentToCopy),
    }),
    normalize: appointment,
  },
})

export const setAppointmentToCopy = originalAppointment => ({
  type: SET_APPOINTMENT_TO_COPY,
  payload: { originalAppointment },
})

export const update = data => ({
  types: UPDATE,
  payload: { request: data },
  meta: {
    fetch: fetchRequest.bind(null, API, {
      body: JSON.stringify(data),
      method: 'PATCH',
    }),
    normalize: appointment,
  },
})

export const remove = id => ({
  types: REMOVE,
  payload: { request: { id } },
  meta: {
    fetch: fetchRequest.bind(null, API, {
      body: JSON.stringify({ id }),
      method: 'DELETE',
    }),
  },
})

export const getAll = () => ({
  types: GET_ALL,
  meta: {
    fetch: fetchRequest.bind(null, API),
    normalize: appointmentArray,
  }
})

export const getAllByPatient = (patientId) => ({
  types: GET_ALL_BY_PATIENT,
  payload: { request: { patientId } },
  meta: {
    fetch: fetchRequest.bind(null, `${API}/patient/${patientId}`),
    normalize: appointmentArray,
    shouldFetch: (state) => {
      const deferreds = selectors.getDeferreds(state)
      if (deferreds.has(patientId)) {
        return deferreds.get(patientId).promise
      }
      const appointmentsFetched = selectors.getAppointmentsFetched(state)
      if(appointmentsFetched && appointmentsFetched.has(patientId)) {
        return Promise.resolve(selectors.getAll(state))
      }
      return undefined
    },
  }
})

export const getByDate = (startDate, endDate) => ({
  types: GET_BY_DATE,
  payload: { startDate, endDate },
  meta: {
    fetch: fetchRequest.bind(null, `${API}/${encodeURIComponent(startDate)}/${encodeURIComponent(endDate)}`),
    normalize: appointmentArray,
  }
})

export const setCurrentDate = (date) => ({
  type: SET_CURRENT_DATE,
  payload: { date },
})

export const setCurrentAppointmentTime = (date) => ({
  type: SET_CURRENT_APPOINTMENT_TIME,
  payload: { date },
})
