import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Loadable from 'react-loadable'

// components
import PrivateRoute from './components/routing/PrivateRoute'
import Loading from './components/loading'
import TreatmentsPage from './pages/TreatmentsPage'

// code split
const load = { loading: () => <Loading /> }
const Login = Loadable({ loader: () => import('./pages/Login'), ...load })
const Logout = Loadable({ loader: () => import('./pages/Logout'), ...load })
const ResetPassword = Loadable({ loader: () => import('./pages/ResetPassword'), ...load })
const Admin = Loadable({ loader: () => import('./pages/Admin'), ...load })
const VerifyAccount = Loadable({ loader: () => import('./pages/VerifyAccount'), ...load })
const Profile = Loadable({ loader: () => import('./pages/Profile'), ...load })
const Help = Loadable({ loader: () => import('./pages/Help'), ...load })
const Home = Loadable({ loader: () => import('./pages/Home'), ...load })
const ForgotPassword = Loadable({ loader: () => import('./pages/ForgotPassword'), ...load })
const Signup = Loadable({ loader: () => import ('./pages/Signup'), ...load})
const PatientsPage = Loadable({ loader: () => import ('./pages/PatientsPage'), ...load})

const Routes = () => {
  return (
    <Switch>
      {/* No Login required */}
      <Route path={'/login'} component={Login} />
      <Route path={'/signup'} component={Signup} />
      <Route path={'/verifyAccount'} component={VerifyAccount} />
      <Route path={'/forgotPassword'} component={ForgotPassword} />
      <Route path={'/resetPassword'} component={ResetPassword} />
      <Route path={'/logout'} component={Logout} />
      <Route path={'/help'} component={Help} />
      {/* Login required */}
      <PrivateRoute path={'/profile'} component={Profile} />
      <PrivateRoute path={'/patients'} component={PatientsPage} />
      <PrivateRoute path={'/treatments'} component={TreatmentsPage} />
      <PrivateRoute path={'/admin-panel'} component={Admin} />
      <Route path={'/'} component={Home} />
    </Switch>
  )
}

export default Routes
